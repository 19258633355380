.primary-button {
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  justify-content: center;

  border: none;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background: transparent;

  font: inherit;
  text-decoration: none;

  transition: color 0.3s, background-color 0.3s;

  &.variant_ {
    &default {
      padding: 1em 2em;

      background: var(--color);
      border-radius: 2em;

      color: white;
      font-weight: 500;

      &.state_ {
        &disabled {
          background: var(--inactive-weak);
          color: var(--on-inactive-weak);
        }
      }
    }

    &secondary {
      box-sizing: border-box;
      padding: calc(1em - 1px) calc(2em - 1px);
      border: 1px solid var(--color);
      border-radius: 2em;
      color: var(--color);
    }

    &underline {
      position: relative;

      padding: 0;

      border-bottom: 1px solid currentColor;
      font-size: 14px;

      font-weight: 500;
      letter-spacing: 0.05em;
      line-height: 18.41px;
    }
  }
}
