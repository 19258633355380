.login-page {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--dugout);
  .header {
    background: var(--dugout);
  }

  .form-container {
    overflow: auto;
    width: 100%;
    max-width: 300px;
    max-height: 100%;
    .login-form {
      max-width: 100%;
    }
    .title {
      padding: 0 10px;

      letter-spacing: 1px;
      text-align: left;
    }
    .submit {
      display: flex;
      width: calc(100% - 20px);
      justify-content: center;
      margin: 0 10px;

      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  .forgot,
  .back {
    width: fit-content;
    width: -moz-fit-content;
    margin: 10px;

    color: var(--strong);

    cursor: pointer;
    text-decoration: none;
    .icon {
      display: inline-block;

      transition: transform 0.3s;
    }
    &.forgot:hover .icon {
      transform: translateX(5px);
    }
    &.back:hover .icon {
      transform: translateX(-5px);
    }
  }
  .signup-link {
    margin: 20px 0;

    color: var(--strong);
    text-align: center;
    a {
      color: var(--primary);
      font-weight: 500;
      text-decoration: none;
    }
  }
}
.forgot-password-form {
  width: 90vw;
  max-width: 500px;
  .container {
    display: flex;
    .contact {
      flex: 3;
    }
  }
  .message {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 400px) {
  .forgot-password-form .container {
    display: block;
  }
}
